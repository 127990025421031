<template>
  <v-main fill-height style="background: #eee; height: 100vh" fluid>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <h1 class="text-h5">You have found a page that does not exists.</h1>
          <p>
            Breathe in, and on the out breath, go back and try again or visit the
            <router-link to="/dashboard" class="base-link">desktop</router-link>.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'NotFoundView'
};
</script>
